<template>
  <div>

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Categories'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <DictionarySelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.sem.category.add')" class="float-right" v-b-modal.modal-new-category>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new category
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-category" title="New category" ok-title="Save" @ok="saveCategory" size="lg">
            <CategoryNew />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" ref="PITable" :additionalDataFilter="tableAdditionalDataFilter" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 100px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import tdColSafeHTML from '@/components/tdColSafeHTML'
  import tdColAction from '@/components/tdColAction'
  import PageTitle from '@/components/PageTitle.vue'
  import tdColUUID from '@/components/tdColUUID.vue'
  import DictionarySelectPI from '@/components/Dictionary/DictionarySelectPI.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import CategoryNew from '@/components/CategoryNew.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'CategoryList',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },
          { title: this.$t('Ontology'), field: 'ontologyName', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Concept'), field: 'concept', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Code'), field: 'fullCode', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Dictionary'), field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              let retVal = [
                {text: null, icon: 'list-ul', id: row.id, to: {name: 'CategoryDetails', params: {id: row.id}}}
              ]
              return retVal
            }
          }
        ],
        columnsPI: [
          { title: 'Ontology', field: 'ontologyName', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Concept', field: 'concept', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Code', field: 'fullCode', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Dictionary', field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        url: '/categories/datatables',
        key: 1,
        searchableByColumn: true,
        category: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      CategoryNew,
      DictionarySelectPI,
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'dictionaries': this.userSettings.dictionaries
        }
      },
    },
    watch: {
      "category"(){
        this.$forceUpdate()
        this.$bvModal.show('modal-edit-category')
      },
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.dictionaries.length) {
          data = data.filter(row => this.userSettingsPI.dictionaries.includes(row.dictionaryAcronym))
        }
        return data
      },
      openDetails(data) {
        router.push(`/category/${data.id}`)
      },
      saveCategory(){
        this.$store.dispatch('createCategory', HelpStore.item).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Category created`
          })
          router.push(`/category/${response.data.id}`)
        })
      }
    }
  }
</script>
