<template>
  <div>
    <b-form-group
      id="concept-group"
      label="Concept"
      label-for="concept"
    >
      <b-form-input
        id="concept"
        v-model="model.concept"
        placeholder="Concept"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="code-group"
      label="Code"
      label-for="code"
    >
      <b-form-input
        id="code"
        v-model="model.code"
        placeholder="Code"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="ontology-group"
      label="Ontology"
      label-for="ontology"
    >
      <OntologySelect @select="selectOntology" />
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import OntologySelect from '@/components/OntologySelect.vue'
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'CategoryNew',
    data(){
      return {
        model: {
          concept: '',
          code: '',
          ontologyId: null
        }
      }
    },
    components: {
      OntologySelect
    },
    mounted(){
      HelpStore.item = this.model
    },
    methods: {
      selectOntology(ontology){
        this.model.ontologyId = ontology.id
      }
    }
  }
</script>
